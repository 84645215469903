import "./Spacer";
export interface SpacerProps {
  height: string | number;
}
const Spacer = ({ height }: SpacerProps) => {
  return (
    <div
      style={{ height: typeof height === "number" ? `${height}px` : height }}
      className="spacer-padding"
    ></div>
  );
};

export default Spacer;
