import { providers } from "ethers";

export const LOCAL_STORAGE_KEYS = {
  PUBLIC_ADDRESS: "public-address",
  BALANCE: "balance",
};

export const TEST_PUBLIC_WALLETS = {
  ETH_AND_USDC: "0x1B135921ADf0697552cA62c4Fe8B0Ba83aF17982",
  ETH_ONLY: "0x19eA47F28415d3288F8a96d8426f32f7166BE6d7",
};

export const SUPPORTED_TOKENS = ["ETH"];
export const NILLI_CONTRACT_ADDRESS =
  "0xFa3fCf6F1809D24f2c2547Cb231dc728709ba8F8"; // mainnet
export const FARCASTER_AUTH_TOKEN =
  "MK-M046L8IDFyxHMxTB98zr3cn3XqHVeP8XZyhRBPqSeJ7b4RL28uS+lnE34fu+EvZteVvApjnJ0Ub3BgnWx1pVTg==";

export const TEST_FARCASTER_PUBLIC_ADDRESS =
  "0x9d363fc4bc4d94c135d004f84ca5a44643ffb816";

export const SANITY_MUTATE_URL =
  "https://lklbypd7.api.sanity.io/v2021-10-21/data/mutate/production?dryRun=false&returnDocuments=true";

export const BORING_AVATAR_COLOR_PALLET = [
  "#91e083",
  "#d189ef",
  "#82c4f5",
  "#7272ed",
];

export const BORING_AVATAR_FALLBACK_NAME = "Maria Mitchell";

const INFURA_API_KEY = "c6b2ba1ecc32438aaad776f97456690b";

const NETWORK_CONFIG = {
  [providers.getNetwork("goerli").chainId]: {
    infuraURL: `https://goerli.infura.io/v3/${INFURA_API_KEY}`,
    theGraphURL: "https://api.thegraph.com/subgraphs/name/marty-nilli/nilli-v2",
    nilliContractAddress: "0x5562E5ba34428CfDc37E5FD8FF3998fD608AEED0",
    daiAddress: "0x75Ab5AB1Eef154C0352Fc31D2428Cef80C7F8B33",
  },

  [providers.getNetwork("mainnet").chainId]: {
    infuraURL: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
    theGraphURL:
      "https://api.thegraph.com/subgraphs/name/nilli-inc/nilli-mainnet",
    nilliContractAddress: "0xFa3fCf6F1809D24f2c2547Cb231dc728709ba8F8",
    daiAddress: "0x6b175474e89094c44da98b954eedeac495271d0f",
  },

  [providers.getNetwork("optimism").chainId]: {
    infuraURL: `https://optimism-mainnet.infura.io/v3/${INFURA_API_KEY}`,
    theGraphURL: "https://api.thegraph.com/subgraphs/name/nilli-inc/nilli",
    nilliContractAddress: "0x7B695B341e36622aB201f531Def8f9Bb11bff982",
    daiAddress: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
  },
};

export const NETWORK_ID = process.env.REACT_APP_NETWORK_ID
  ? parseInt(process.env.REACT_APP_NETWORK_ID)
  : providers.getNetwork("mainnet").chainId;

export const CONFIG = NETWORK_CONFIG[NETWORK_ID];
