import React, { createContext, useEffect, useState } from "react";

const DEFAULT_ETHER_PRICE = 1557;

export const fetchCoingeckoPrice = async (base: string, quote: string) => {
  try {
    const baseId = base.toLowerCase();
    const quoteId = quote.toLowerCase();
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${baseId}&vs_currencies=${quoteId}`;
    const data = await fetch(url, {
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    const price = result[baseId][quoteId];
    return price;
  } catch {
    return undefined;
  }
};
const PriceContext = createContext(DEFAULT_ETHER_PRICE);

export const EthereumPriceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [price, setPrice] = useState(DEFAULT_ETHER_PRICE);
  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timer>();
  useEffect(() => {
    const timerFunction = async () => {
      const newPrice = await fetchCoingeckoPrice("ethereum", "usd");
      if (newPrice) {
        setPrice(newPrice);
      }
      console.log({ newPrice });
    };
    timerFunction();
    setIntervalTimer(setInterval(timerFunction, 15000));
    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  return (
    <PriceContext.Provider value={price}>{children}</PriceContext.Provider>
  );
};

export const useEthereumPrice = () => {
  const value = React.useContext(PriceContext);
  return value;
};

export default useEthereumPrice;
