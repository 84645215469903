import axios from "axios";

import { formatEther } from "ethers/lib/utils";
import { useContext, useEffect, useState } from "react";

import Avatar from "boring-avatars";
import { BigNumber, utils } from "ethers";
import {
  useAccount,
  useBalance,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";

import { AppContext } from "../../../App";
import dai from "../../../abi/dai.json";
import nilli from "../../../abi/nilli.json";
import useContractState from "../../../hooks/useContractState";
import { Signer } from "../../../stubs/signers";
import { SmartContract } from "../../../types/SmartContract";
import { SupportedTokensType } from "../../../types/common";
import {
  BORING_AVATAR_COLOR_PALLET,
  BORING_AVATAR_FALLBACK_NAME,
  CONFIG,
  SUPPORTED_TOKENS,
} from "../../../utils/constants";
import {
  ellipsisBetweenWord,
  getSanityContractListOffChainDataURL,
  getUserSignerListFromContract,
} from "../../../utils/utils";
import IconToken, { IconEthProps } from "../../Common/Icons/TokenIcon";
import SignersList from "../../Common/SignersList/SignersList";
import arrowIcon from "./../../../assets/arrow-icon.svg";
import dropdownIcon from "./../../../assets/dropdown-icon.svg";
import modalCloseIcon from "./../../../assets/modal-close-icon.svg";
import newGreenLogo from "./../../../assets/nilli-yellow-2.svg";
import { isMobile } from "react-device-detect";
import { ReactComponent as ShareIcon } from "./../../../assets/share-grey.svg";
import signIcon from "./../../../assets/sign-icon.png";
import "./Sign.scss";

export interface SignProps {
  shouldClose: () => void;
  contract?: SmartContract;
}
const Sign = ({ shouldClose, contract }: SignProps) => {
  const { userProfile } = useContext(AppContext);
  const [stakeExpanded, setStakeExpanded] = useState(false);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [stakeValue, setStakeValue] = useState<number>();
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const [selectedToken, setSelectedToken] =
    useState<SupportedTokensType>("ETH");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [lockDropdown, setLockDropdown] = useState(false); // For MVP. Remove later

  const { address } = useAccount();

  const account = address;

  const { data: daiBalance } =
    useBalance({ token: CONFIG.daiAddress as any, address }) ?? 0;
  console.log(formatEther(daiBalance?.value || 0));

  let value = utils.parseEther("0");
  console.log(value);
  try {
    value = utils.parseEther(`${stakeValue ?? 0}`);
  } catch {}
  const { config } = usePrepareContractWrite({
    address: CONFIG.nilliContractAddress as any,
    abi: nilli,
    functionName: "depositToContract",
    args: [contract?.onChain?.contractAddress],
    overrides: {
      value,
      gasLimit: BigNumber.from("110000"),
    },
  });
  const { writeAsync: sendEth } = useContractWrite(config);

  const { config: configDaiApprove } = usePrepareContractWrite({
    address: CONFIG.daiAddress as any,
    abi: dai,
    functionName: "approve",
    args: [contract?.onChain?.contractAddress, value],
  });

  const { writeAsync: approveToken } = useContractWrite(configDaiApprove);

  const { config: sendTokenConfig } = usePrepareContractWrite({
    address: CONFIG.nilliContractAddress as any,
    abi: nilli,
    functionName: "depTokentoContract",
    args: [contract?.onChain?.contractAddress, CONFIG.daiAddress, value],
    overrides: {
      gasLimit: BigNumber.from("110000"),
    },
  });
  const { writeAsync: sendToken } = useContractWrite(sendTokenConfig);

  const { data: bal } = useBalance({ address: account }) ?? 0;
  const balance = bal?.value ?? 0;
  useEffect(() => {
    console.log({ balance });
    switch (selectedToken) {
      case "ETH":
        setTokenBalance(+formatEther(balance));
        break;
      case "USDC":
        setTokenBalance(+balance.toString());
        break;
      case "DAI":
        setTokenBalance(+formatEther(daiBalance?.value.toString() ?? 0));
        break;
    }
  }, [selectedToken, balance, daiBalance]);

  useEffect(() => {
    console.log("SIGN MOUNTED!!!");
    if (!!contract && contract.onChain?.contractAddress) {
      fetchSigners(); // TODO: State management!!!!
    }
  }, [contract]);

  const fetchSigners = async () => {
    const contractInfoResponse = await axios({
      url: getSanityContractListOffChainDataURL(
        contract!.onChain?.contractAddress
      ),
      method: "GET",
    });
    // TODO: Error Handling!!

    const signersListInfoResponse = await axios({
      url: getUserSignerListFromContract(
        contractInfoResponse.data.result[0].id
      ),
      method: "GET",
    });
    // TODO: Error Handling!!
    if (!!signersListInfoResponse.data.result) {
      const signersOfContract = signersListInfoResponse.data.result.map(
        (signerObj: any) => {
          const userAddr = signerObj.userAddr[0];

          return {
            id: userAddr ? userAddr._id : undefined,
            publicAddress: signerObj.signer,
            imageUrl: userAddr ? userAddr.avatarURL : undefined,
            isFarcasterVerified: userAddr ? !!userAddr.farcasterhandle : false,
            name: userAddr ? userAddr.publicname : undefined,
            userName: userAddr ? userAddr.farcasterhandle : undefined,
            dateSigned: signerObj._createdAt,
          } as Signer;
        }
      );

      console.log({ testing: signersOfContract });
      setSigners(signersOfContract);
    }
  };

  const onStakeInputChange = (value: any) => {
    if (value && value.trim() !== "") {
      setStakeValue(+value);
    } else {
      setStakeValue(value);
    }

    if (value > tokenBalance) {
    }
  };

  const getStakeState = (): IconEthProps["state"] => {
    if (!stakeValue) return "disabled";
    if (stakeValue <= tokenBalance) return "success";
    if (stakeValue > tokenBalance) return "error";
    return "enabled";
  };

  const stakeEth = (contractAddress: string) => {
    sendEth?.()
      .then((res) => {
        res.wait();
        console.log("Staking Response", res);
        // TODO: How do we get the confirmation that transaction was successful?
        if (!!res) {
          setLockDropdown(true);
          setStakeValue(undefined);
          setStakeExpanded(false);
        }
      })
      .catch((err) => console.log("Error Staking: ", err));
  };

  const stakeToken = async (contractAddress: string) => {
    try {
      const approveTokenResponse = await approveToken!().then((tx) =>
        tx.wait()
      );
      if (!!approveTokenResponse) {
        const sendTokenResponse = await sendToken!().then((tx) => tx.wait());
        if (!!sendTokenResponse) {
          setLockDropdown(true);
          setStakeValue(undefined);
          setStakeExpanded(false);
        }
      }
    } catch (error) {
      console.log("Error Approving/Staking token", error);
    }
  };

  const onStake = () => {
    const CONTRACT_WITH_PENDING_STATE = contract?.onChain?.contractAddress; // TODO: contract?.onChain?.contractAddress - Change it once subgraph issue is fixed
    if (selectedToken === "ETH" && contract?.onChain?.contractAddress) {
      stakeEth(contract?.onChain?.contractAddress);
    } else if (contract?.onChain?.contractAddress) {
      stakeToken(contract?.onChain?.contractAddress);
    }
  };

  const ethStakedByCurrentUser =
    (useContractState({
      contractAddress: contract?.onChain?.contractAddress,
      method: "depositsOf",
      args: [account],
    }) as BigNumber) || undefined;
  console.log({ ethStakedByCurrentUser, gt: ethStakedByCurrentUser?.gt(0) });
  return (
    <div className={`sign ${isMobile ? "mobile" : ""}`}>
      <div className="non-stake" onClick={() => setStakeExpanded(false)}>
        <div className="header-content">
          <img
            onClick={() => shouldClose()}
            className="modal-close"
            src={modalCloseIcon}
          />
          <p className="heading">
            {ethStakedByCurrentUser?.gt(0)
              ? `😎 You’ve staked ${formatEther(
                  ethStakedByCurrentUser
                )} ${selectedToken} to`
              : "🥳 You've signed!"}
          </p>
          <p className="title">{contract?.onChain?.title}</p>
          {userProfile &&
            (userProfile.profilePicture ? (
              <div className="avatar">
                <img className="logo" src={newGreenLogo} />
                <img className="profile" src={userProfile?.profilePicture} />
              </div>
            ) : (
              <div className="avatar">
                <Avatar
                  size={71}
                  name={userProfile?.id ?? BORING_AVATAR_FALLBACK_NAME}
                  variant="marble"
                  colors={BORING_AVATAR_COLOR_PALLET}
                />
                <img className="logo" src={newGreenLogo} />
              </div>
            ))}
        </div>


{/*
        <ShareIcon className="share-icon" />
         <p className="description">
         Sharing is caring! Help spread the word about this crowd contract via
          your social network.
        </p>
        
*/}

        
        <p className="signers-count">
          <img src={signIcon} />
          <span>{`${signers.length} Signers`}</span>
        </p>
        <SignersList
          contractAddress={contract?.onChain?.contractAddress}
          signers={signers ?? []}
        />
      </div>


      <div
        onClick={(e) => {
          e.stopPropagation();
          setStakeExpanded(true);
        }}
        className={`stake-window ${
          stakeExpanded ? "expanded" : ""
        } ${getStakeState()}`}
      >
        <div
          className="toggle"
          onClick={() => setStakeExpanded((s) => !s)}
        ></div>
        <div className="title">
          <img src={signIcon} />
          <p>
            {ethStakedByCurrentUser?.gt(0)
              ? "Increase stake"
              : "Add your stake"}
          </p>
        </div>
        <div className="input-wrapper">
          <IconToken
            token={selectedToken}
            className="currency-icon"
            state={(stakeValue ?? 0) > 0 ? "enabled" : "disabled"}
          />
          <input
            onClick={() => setStakeExpanded(true)}
            value={stakeValue}
            onChange={(e) => onStakeInputChange(e.target.value)}
            type="number"
            placeholder="0"
            className="external"
          />
          {!lockDropdown && (
            <div className="currency-dropdown">
              <p onClick={() => setDropdownExpanded(!dropdownExpanded)}>
                {selectedToken}
              </p>
              {dropdownExpanded && (
                <div className="list-wrapper">
                  {SUPPORTED_TOKENS.map((token, index) => (
                    <div
                      onClick={() => {
                        setSelectedToken(token as SupportedTokensType);
                        setDropdownExpanded(false);
                      }}
                      className="list-item"
                      key={index}
                    >
                      {token}
                    </div>
                  ))}
                </div>
              )}

              <img src={dropdownIcon} />
            </div>
          )}
        </div>
        <div className="balance-details external">
          <p className="available-balance">
            {+tokenBalance.toFixed(5)} {selectedToken} available
          </p>

          <div className="public-address">
            <IconToken
              token={selectedToken}
              className="public-address-img"
              state={getStakeState()}
            />
            <p>{ellipsisBetweenWord(account ?? "", 6, 4)}</p>
          </div>
        </div>
        {stakeExpanded && (
          <>
            <button
              disabled={getStakeState() !== "success"}
              className="stake-btn"
              onClick={() => onStake()}
            >
              Stake
              <img className="arrow-icon" src={arrowIcon} />
            </button>
            <p className="learn-more-text">
              <span>
                Stakers may reclaim all of their
                funds if contract is invalidated.{" "}
                <a
                  href="https://nilli.gitbook.io/docs/how-nilli-beta-works"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Learn more.{" "}
                </a>
              </span>
              <span>
                
                
                By staking, you agree to our{" "}
                <a
                  href="https://nilli.com/tos"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#808080" }}
                >
                  Terms of service
                </a>{" "}
                and{" "}
              </span>
              <span>
                <a
                  href="https://nilli.com/privacy"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#808080" }}
                >
                  Privacy Policy.
                </a>
              </span>
            </p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setStakeExpanded(false);
              }}
              className="cancel-btn"
            >
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Sign;
