import "@rainbow-me/rainbowkit/styles.css";
import "react-modern-drawer/dist/index.css";
import "./App.scss";

import { formatEther } from "@ethersproject/units";
import axios from "axios";
import { providers } from "ethers";
import { createContext, useEffect, useRef, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import Hero from "./components/Common/Hero/Hero";
import Menu from "./components/Common/Menu/Menu";
import SocialSign from "./components/Common/SocialSign/SocialSign";
import Spacer from "./components/Common/Spacer/Spacer";
import TabNavigation from "./components/Common/TabNavigation/TabNavigation";
import { CustomConnectButton } from "./components/Common/WalletConnect/WalletConnect";
import RainbowWrapper from "./components/RainboxWrapper";
import ContractListing from "./components/Screens/ContractListing/ContractListing";
import Layout from "./components/Structure/Layout/Layout";
import { EthereumPriceProvider } from "./hooks/useEthereumPrice";
import { SmartContract } from "./types/SmartContract";
import { UserProfile } from "./types/Users";
import { CONFIG, NETWORK_ID, SANITY_MUTATE_URL } from "./utils/constants";
import { getUserFromAddressURL } from "./utils/utils";
import launchcasterLogo from "./assets/launchcaster.svg";


export const REPLACE_YO_WALLET_TO_THIS_FOR_TESTING = "0x002";

const CONTRACT_LIST_QUERY = `
{
  nilliContracts {
    blockNumber
    blockTimestamp
    condition1
    conditionFlags
    contractAddress
    creator
    curState
    expirationDate
    id
    targetAmount
    qtySigners
    recDisplayName
    title
    transactionHash
  }
}
`;

export interface AppContext {
  userProfile?: UserProfile;
  setUserProfile?: (user?: UserProfile) => void;
  openWalletModal?: () => void;
  openShareDrawer?: () => void;
}
export const AppContext = createContext<AppContext>({});

function App() {
  const connectRef = useRef();

  const [contracts, setContracts] = useState<SmartContract[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isContractCreationModalOpened, setIsContractCreationModalOpened] =
    useState<boolean>(false);
  const [isShareDrawerOpen, setIsShareDrawerOpen] = useState<boolean>(false);

  const [isSocialSignDrawerOpen, setIsSocialSignDrawerOpen] =
    useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>();

  const { address } = useAccount();
  const { chain } = useNetwork();

  const fetchContracts = async () => {
    const response = await axios({
      url: CONFIG.theGraphURL,
      method: "POST",
      data: {
        query: CONTRACT_LIST_QUERY,
      },
    });

    console.log({ data: response.data });
    setContracts(mapToContractObj(response.data));
  };

  // This should be refactred later on
  const closeOtherModals = () => {
    setIsMenuOpen(false);
    setIsContractCreationModalOpened(false);
    setIsShareDrawerOpen(false);
  };

  const mapToContractObj = (data: any): SmartContract[] => {
    const getConditions = (contract: any) => {
      const conditions: {
        isValidated: boolean;
        text: string;
      }[] = [];
      if (!!contract?.condition1) {
        conditions.push({
          text: contract.condition1,
          isValidated: contract.conditionFlags[0] ?? false,
        });
      }
      if (!!contract?.condition2) {
        conditions.push({
          text: contract.condition2,
          isValidated: contract.conditionFlags[1] ?? false,
        });
      }
      if (!!contract?.condition3) {
        conditions.push({
          text: contract.condition3,
          isValidated: contract.conditionFlags[2] ?? false,
        });
      }

      return conditions;
    };

    return data.data.nilliContracts.map((c: any) => {
      return {
        id: c.id,
        onChain: {
          conditions: getConditions(c),
          title: c.title,
          state: c.curState, // TODO: confirm
          expirationDate: c.expirationDate,
          contractAddress: c.contractAddress,
        },
        offChain: {
          stakedFor: {
            label: c.recDisplayName, // TODO confirm
          },
          targetAmount: +formatEther(c.targetAmount),
          creator: {
            label: c.creator,
          },
          signers: +c.qtySigners,
        },
      } as SmartContract;
    });
  };

  // useDapp config start*************************

  const onThumbClick = async () => {
    setIsMenuOpen(false);
    if (!!address && userProfile?.id) {
      return setIsSocialSignDrawerOpen(!isSocialSignDrawerOpen);
    }

    try {
      (connectRef.current! as any).click();
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUserFromAddress = async (account: string) => {
    try {
      const response = await axios({
        url: getUserFromAddressURL(account),
        method: "GET",
      });
      const responseResult = response?.data?.result;
      if (!!responseResult && responseResult[0]) {
        setUserProfile({
          id: responseResult[0]?._id,
          farcasterhandle: responseResult[0]?.farcasterhandle,
          name: responseResult[0]?.publicname,
          profilePicture: responseResult[0]?.avatarURL,
          twitterfollowers: responseResult[0]?.twitterfollowers,
          twitterhandle: responseResult[0]?.twitterhandle,
          wallet: responseResult[0]?.wallet,
        });
      } else if (!responseResult.length && !userProfile) {
        // create user:
        createUser();
      }
    } catch (err) {
      console.log(err);
    }
    // setIsSocialSignDrawerOpen(true);
  };

  const createUser = async () => {
    console.log("CREARTING USER");
    const response = await axios({
      url: SANITY_MUTATE_URL,
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SANITY_AUTH_TOKEN}`,
      },
      data: {
        mutations: [
          {
            createOrReplace: {
              _type: "user",
              wallet: address,
            },
          },
        ],
      },
    });
    console.log({ CREATING_USER: response.data.re });
    if (
      response.status === 200 &&
      response?.data?.results &&
      response.data.results[0]
    ) {
      // todo refactor
      const responseObj = response.data.results[0].document;
      setUserProfile({
        id: responseObj._id,
        wallet: responseObj.wallet,
      });
      setIsSocialSignDrawerOpen(true);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  useEffect(() => {
    if (address) {
      fetchUserFromAddress(address);
    } else {
      setUserProfile(undefined);
    }
  }, [address]);

  console.log({ chainId: chain?.id, NETWORK_ID });
  if (chain?.id && chain.id !== NETWORK_ID) {
    return (
      <p>
        Please use{" "}
        {NETWORK_ID === 1
          ? "ethereum mainnet"
          : providers.getNetwork(NETWORK_ID).name}{" "}
        network!
      </p>
    );
  }

  return (
    <div className="App">
      <RainbowWrapper>
        <EthereumPriceProvider>
          <AppContext.Provider
            value={{
              userProfile: userProfile,
              setUserProfile: (user) => setUserProfile(user),
              openWalletModal: () => onThumbClick(),
              openShareDrawer: () => {
                closeOtherModals();
                setIsShareDrawerOpen(!isShareDrawerOpen);
              },
            }}
          >
            <CustomConnectButton btnRef={connectRef} />
            <TabNavigation
              onListClick={() => {
                closeOtherModals();
                setIsMenuOpen(!isMenuOpen);
              }}
              onContractCreateClick={(value) => {
                closeOtherModals();
                setIsContractCreationModalOpened(value);
              }}
              isContractCraeteModalOpen={isContractCreationModalOpened}
              onThumbClick={() => onThumbClick()}
              isMenuOpen={isMenuOpen}
            />
            <div className="launchcaster-container">
              <img src={launchcasterLogo} alt="Launchaster-Logo" className="launchcaster-logo" />
              <span className="banner-msg">Coming soon to Launchcaster →</span>
            </div>
            <Layout>
              {/* <SearchBar
            placeholder="Search contracts"
            onSearch={(text) => console.log("Searching...", text)}
          /> */}
              <Hero
                isShareDrawerOpen={isShareDrawerOpen}
                setIsShareDrawerOpen={(value: boolean) => {
                  closeOtherModals();
                  setIsShareDrawerOpen(value);
                }}
              />
              <ContractListing contracts={contracts} />
              <Spacer height={60} />
              <SocialSign
                isOpen={isSocialSignDrawerOpen}
                onClose={() => setIsSocialSignDrawerOpen(false)}
              />
              <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
            </Layout>
          </AppContext.Provider>
        </EthereumPriceProvider>
      </RainbowWrapper>
    </div>
  );
}

export default App;
