import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Signer } from "../../../stubs/signers";
import { SupportedTokensType } from "../../../types/common";
import { ellipsisBetweenWord } from "../../../utils/utils";
import Spacer from "../Spacer/Spacer";
import ethToken from "./../../../assets/eth-sign-list.svg";
import farcasterVerified from "./../../../assets/farcaster-verified.svg";
import nilliIcon from "./../../../assets/nilli-icon-yellow.svg";
import signersIcon from "./../../../assets/sign-icon.png";
import twitterVerified from "./../../../assets/twitter-verified.svg";
import usdcToken from "./../../../assets/usdc-sign-list.svg";
import "./SignersList.scss";

import "./SignersList.scss";

import Avatar from "boring-avatars";
import dayjs, { QUnitType } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { formatEther } from "ethers/lib/utils";
import useContractStates from "../../../hooks/useContractStates";
import useEthereumPrice from "../../../hooks/useEthereumPrice";
import { BORING_AVATAR_COLOR_PALLET } from "../../../utils/constants";

dayjs.extend(localizedFormat);

const MINUTE_LENGTH_IN_SECONDS = 60;

export interface SignersListProps {
  signers: Signer[];
  contractAddress?: string;
}

const SignersList = ({
  signers: signersProp,
  contractAddress,
}: SignersListProps) => {
  const { address: account } = useAccount();
  const [filterState, setFilterState] = useState<
    "▼ Most Staked" | "▼ Nilli Earned" | "▼ Date Signed" | string
  >("▼ Most Staked");
  const [signers, setSigners] = useState<Signer[]>(signersProp);

  const etherPrice = useEthereumPrice();

  const ethStakedBySigners = useContractStates({
    contractAddress,
    method: "depositsOf",
    walletAddresses: signersProp
      .map((s) => s.publicAddress)
      .filter((address) => !!address) as string[],
  });

  useEffect(() => {
    if (filterState === "▼ Nilli Earned") {
      setSigners([
        ...signersProp.sort(
          (x, y) => (y.nilliCoins ?? 0) - (x.nilliCoins ?? 0)
        ),
      ]);
    } else if (filterState === "▼ Most Staked") {
      setSigners([
        ...signersProp.sort(
          (x, y) =>
            ethStakedBySigners[y.publicAddress!] -
            ethStakedBySigners[x.publicAddress!]
        ),
      ]);
    } else if (filterState === "▼ Date Signed") {
      setSigners([
        ...signersProp.sort(
          (x, y) =>
            Date.parse(x.dateSigned ?? "") - Date.parse(y.dateSigned ?? "")
        ),
      ]);
    }
  }, [filterState, signersProp]);

  const getTokenImg = (tokenName: SupportedTokensType) => {
    if (tokenName === "ETH") {
      return ethToken;
    } else return usdcToken;
  };

  const getParsedDateString = (dateSigned: string) => {
    const currentDateTimestamp = dayjs(Date.now());
    const dateSignedTimestamp = dayjs(Date.parse(dateSigned));

    const differenceIn = (value: QUnitType) => currentDateTimestamp.diff(
      dateSignedTimestamp,
      value,
    );

    const differenceInSeconds = currentDateTimestamp.diff(
      dateSignedTimestamp,
      "s"
    );
    const appropriateFormatString = [];

    differenceIn("M") > 0 && appropriateFormatString.push('M[M]');
    differenceIn("d") > 0 && appropriateFormatString.push('d[d]');
    differenceIn("h") > 0 && appropriateFormatString.push('h[h]');
    differenceIn("m") > 0 && appropriateFormatString.push('m[m]');

    if (differenceInSeconds <= MINUTE_LENGTH_IN_SECONDS) {
      return <p><b>Just Now</b></p>;
    }

    return (
      <p>
        <b>{dayjs(differenceInSeconds).format(appropriateFormatString.slice(0, 1).join(' '))}&nbsp;</b>
        ago
      </p>
    );
  };

  const getLastColumnData = (signer: Signer) => {
    switch (filterState) {
      case "▼ Most Staked":
        return signer.publicAddress &&
          ethStakedBySigners[signer.publicAddress] ? (
          <>
            <p className="usd">
              $
              {(
                +formatEther(ethStakedBySigners[signer.publicAddress]) *
                +etherPrice
              ).toFixed(2)}
            </p>
            {ethStakedBySigners[signer.publicAddress] ? (
              <div className="token-value">
                <img src={getTokenImg("ETH")} className="token-img" />
                <p>{formatEther(ethStakedBySigners[signer.publicAddress])}</p>
              </div>
            ) : (
              <div className="token-value">
                <p>__</p>
              </div>
            )}
          </>
        ) : null;
      case "▼ Nilli Earned":
        return (
          <div className="nilli-earned">
            <img src={nilliIcon} />
            <p>{signer.nilliCoins ?? 0}</p>
          </div>
        );
      case "▼ Date Signed":
        return (
          <div className="date-signed">
            <img src={signersIcon} />
            {signer.dateSigned ? getParsedDateString(signer.dateSigned) : "NA"}
          </div>
        );
      default:
        break;
    }
  };

  const getSignerCell = (signer: Signer, index: number, current?: boolean) => {
    if (!signer) return null;

    //TODO: This whole table should be properly rewritten to the display grid system, so that it wont break on both desktop & mobile.
    const MIN_DOUBLE_VALUE = 9;
    const MIN_HUNDREDS_VALUE = 99;
    const columnClassName = index >= MIN_DOUBLE_VALUE
      ? 'margin-left-3' : index >= MIN_HUNDREDS_VALUE
        ? 'margin-left-5' : '';

    return (
      <div key={index} className={`signer-cell ${current ? "current" : ""}`}>
        <div className="sn">
          <p className={columnClassName}>{current ? signers.indexOf(signer) + 1 : index + 1}</p>
        </div>
        <div className="profile">
          {signer.imageUrl ? (
            <img className="avatar-signer" src={signer.imageUrl} />
          ) : (
            <div className="avatar-signer">
              <Avatar
                size={44}
                name={signer.id}
                variant="marble"
                colors={BORING_AVATAR_COLOR_PALLET}
              />
            </div>
          )}

          <div className="name-grp">
            <p className="name">
              {signer.name}
              {(signer.isFarcasterVerified || signer.isTwitterVerified) && (
                <img
                  src={
                    signer.isFarcasterVerified
                      ? farcasterVerified
                      : twitterVerified
                  }
                />
              )}
            </p>
            {signer.userName && <p className="sub-name">@{signer.userName}</p>}
            {!signer.name && !signer.userName && (
              <p className="sub-name">
                {ellipsisBetweenWord(signer.publicAddress!, 7, 4)}
              </p>
            )}
          </div>
        </div>
        <div className="amount">{getLastColumnData(signer)}</div>
      </div>
    );
  };


  return (
    <div className="signers-list">
      <div className="header">
        <p>RK</p>
        <p>Signer</p>
        <select
          className="filter-dropdown"
          onChange={(selected) => setFilterState(selected.target.value)}
        >
          <option value="▼ Most Staked">▼ Most Staked</option>
          <option value="▼ Date Signed">▼ Date Signed</option>
          <option value="▼ Nilli Earned">▼ Nilli Earned</option>
        </select>
      </div>
      {account &&
        !!signers &&
        getSignerCell(
          signersProp.filter((s) => s.publicAddress === account)[0],
          0,
          true
        )}

      {/* TODO: Thats fixes the sorting issues functionality, because it does not run into the loop as it runs with useEffect fix,
          so it should be improved to react properly on ethStakedBySigners change */}
      {signers && filterState === "▼ Most Staked"
        ? signers
            .sort(
              (x, y) =>
                ethStakedBySigners[y.publicAddress!] -
                ethStakedBySigners[x.publicAddress!]
            )
            .map((signer, index) => getSignerCell(signer, index))
        : signers.map((signer, index) => getSignerCell(signer, index))}
      <Spacer height={310} />
    </div>
  );
};

export default SignersList;
