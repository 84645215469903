import Drawer from "react-modern-drawer";
import {
  EmailShareButton,
  TelegramShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import nilliLogo from "./../../../assets/nilli-logo.svg";
import { ReactComponent as PlaneIcon } from "./../../../assets/nilli-plane.svg";
import smallLogo from "./../../../assets/nilli-small-logo.svg";

import {
  EmailIcon,
    TelegramIcon,
  FacebookIcon,
  RedditIcon,
  TwitterIcon,
} from "react-share";

import { useAccount } from "wagmi";
import "./Hero.scss";
import { useState } from "react";

export interface HeroProps {
  isShareDrawerOpen: boolean;
  setIsShareDrawerOpen: (value: boolean) => void;
}

const Hero = ({ isShareDrawerOpen, setIsShareDrawerOpen }: HeroProps) => {
  const { address: account } = useAccount();

  const [copyLinkText, setCopyLinkText] = useState('🔗 Copy link');

  const copyLink = () => {
    const copyLinkText = 'https://demo.nilli.com';

    navigator.clipboard.writeText(copyLinkText).then(() => {
      setCopyLinkText('🔗 Copied!')

      setTimeout(() => setCopyLinkText('🔗 Copy link'), 1000);
    });
  }
  return (
    <>
      <div className="hero">
        {/* Added appopriate block according to the deisgn */}
        {/* TODO: Implement functionality for plane icon */}
        <div className="hero-top-wrapper">
          <img
            src={smallLogo}
            className="connect-button-image"
            alt="small logo"
          />
          <button
            className="share-button"
            onClick={() => setIsShareDrawerOpen(!isShareDrawerOpen)}
          >
            <PlaneIcon style={{ opacity: !account ? 0.4 : 1 }} />
          </button>
        </div>
        <div className="hero-bottom-wrapper">
          <img src={nilliLogo} alt="logo" />
          <p className="hero-logo-label">Do anything.</p>
        </div>
      </div>
      <Drawer
        open={isShareDrawerOpen}
        onClose={() => setIsShareDrawerOpen(false)}
        direction="bottom"
        style={{
          background: "#151515",
          borderRadius: "20px 20px 0 0",
          zIndex: 102,
        }}
        className="share-drawer"
        size={244}
        lockBackgroundScroll
      >
        <div className="share-wrapper">
          <p className="title">Share via</p>
          <p className='copy-link' onClick={copyLink}>{copyLinkText}</p>
          <div className="button-group">
            <EmailShareButton url="https://demo.nilli.com">
              <EmailIcon size={50} borderRadius={100} />
            </EmailShareButton>
            <TwitterShareButton url="https://demo.nilli.com">
              <TwitterIcon size={50}borderRadius={100}  />
            </TwitterShareButton>
            <TelegramShareButton url="https://demo.nilli.com">
              <TelegramIcon size={50} borderRadius={100} />
            </TelegramShareButton>
            <FacebookShareButton url="https://demo.nilli.com">
              <FacebookIcon size={50} borderRadius={100}  />
            </FacebookShareButton>
            <RedditShareButton url="https://demo.nilli.com">
              <RedditIcon size={50}borderRadius={100}  />
            </RedditShareButton>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Hero;
