export interface SocialLoginLogoProps {
  state: "Logged Out" | "Logged In Twitter" | "Logged In Farcaster";
  className?: string;
}
// TODO: Make this component work with different states
const SocialLoginLogo = ({ state, className }: SocialLoginLogoProps) => {
  const getStrokeColor = () => {
    switch (state) {
      case "Logged Out":
        return "#686868";
      case "Logged In Farcaster":
        return "#8367CB";
      case "Logged In Twitter":
        return "#1d9bf0";
    }
  };
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1" clipPath="url(#clip0_374_4064)">
        <path
          d="M4.94141 49.3267C9.30041 46.4207 12.1793 41.4642 12.1793 35.8491C12.1793 22.6702 22.9002 11.9492 36.0791 11.9492C49.258 11.9492 59.9789 22.6702 59.9789 35.8491C59.9789 46.4953 57.3649 56.5374 52.7479 65.3776"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.7104 68.5089C35.4148 60.3205 40.8602 48.71 40.8602 35.8503C40.8602 33.2091 38.7214 31.0703 36.0802 31.0703C33.439 31.0703 31.3003 33.2091 31.3003 35.8503C31.3003 47.3792 25.7395 57.6317 17.1641 64.0752"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.9023 24.7949C69.1381 28.3052 69.817 32.0803 69.817 36.0116C69.817 45.0012 67.9091 50.141 67.9091 50.141"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.88515 18.8555C3.11685 25.7606 2.20703 32.0819 2.20703 36.0131"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.5781 69.7039C44.1796 62.6698 48.077 54.2301 49.6454 44.9961"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.343 38.5539C50.3837 37.6577 50.4177 36.7547 50.4177 35.8516C50.4177 27.9416 43.9878 21.5117 36.0778 21.5117C28.1677 21.5117 21.7379 27.9416 21.7379 35.8516C21.7379 44.9227 17.019 52.9074 9.91016 57.4973"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.75 9.73684C20.5552 5.03156 27.9492 2.20703 36.0154 2.20703C48.4203 2.20703 59.2567 8.89491 65.1366 18.8554"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.88672 18.8524C7.89839 17.1482 8.91006 15.7631 9.6773 14.8125"
          stroke={getStrokeColor()}
          strokeWidth="4.41332"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_374_4064">
          <rect width="72.0186" height="71.91" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialLoginLogo;
