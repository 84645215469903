import "./Conditions.scss";
import { ReactComponent as ThreeDots } from "./../../../assets/three-dots.svg";
import triangleArrowIcon from "./../../../assets/triangle-arrow.svg";
import { Condition } from "../../../types/SmartContract";
import { useEffect, useState } from "react";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";

export interface ConditionsProps {
  isExpanded: boolean;
  conditions: Condition[];
  cardState: "complete" | "pending" | "delivered" | "failure";
  recepientLabel?: string;
}

const Conditions = ({
  isExpanded,
  conditions,
  cardState,
  recepientLabel,
}: ConditionsProps) => {
  const completedConditions = conditions.filter((c) => c.isValidated);
  const incompleteConditions = conditions.filter((c) => !c.isValidated);

  const [isCompletedExpanded, setCompletedExpanded] = useState(false);

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCompletedExpanded(!isCompletedExpanded);
  };

  const getStateText = () => {
    if (cardState === "complete") return "Complete";
    if (cardState === "delivered") return "Success 👏";
    if (cardState === "failure") return "Failure";
  };

  // Calculates the width of the child element of the expended conditions block 
  // and sets parent block to be the same width as text is to remove unnesesesary paddings
  useEffect(() => {
    if (isExpanded || isCompletedExpanded) {
      conditions.forEach((_, index) => {
        const block = document.getElementsByClassName('conditions-block')[index] as HTMLElement;
        // Checks whether block is opened if it has child conditions block
        if (block) {
          const childWidth = block.getElementsByTagName('p')[0].offsetWidth;
    
          if (childWidth && block) {
            block.style.width = `${childWidth + 1}px`;
          }
        }
      })
    }
  }, [isExpanded, conditions, isCompletedExpanded])

  return (
    <div className="conditions">
      <div
        className={cardState === "pending" ? "state-tag pending" : "state-tag"}
      >
        <div className={cardState === "pending" ? "dot-flashing" : "text"}>
          {cardState !== "pending" && getStateText()}
        </div>
      </div>
      {isExpanded && (
        <>
          {incompleteConditions.map((condition, index) => (
            <div
              className={`conditions-block ${
                cardState === "failure" ? " failure" : "incomplete"
              }`}
              key={index}
            >
              <p>{condition.text}</p>
              <ThreeDots className="static-loading"></ThreeDots>
            </div>
          ))}

          {cardState === "pending" && !!completedConditions.length && (
            <>
              <div
                className={`show-hide-toggle ${
                  isCompletedExpanded ? "expanded" : ""
                }`}
                onClick={(e) => handleToggleClick(e)}
              >
                <p>{isCompletedExpanded ? "Hide " : "Show "} complete</p>
              </div>
              {isCompletedExpanded &&
                completedConditions.map((condition, index) => (
                  <div className="conditions-block completing" key={index}>
                    <p>{condition.text}</p>
                    <div className="static-loading">
                      <p className="validated">Validated</p>
                    </div>
                  </div>
                ))}
            </>
          )}

          {cardState !== "pending" &&
            completedConditions.map((condition, index) => (
              <div className={`conditions-block ${cardState}`} key={index}>
                <p>{condition.text}</p>
                <div className="static-loading">
                  <p className="validated">Validated</p>
                </div>
              </div>
            ))}

          {cardState !== "delivered" && (
            <div
              className={`if-verified-text ${
                isCompletedExpanded ? "expanded" : cardState
              }`}
            >
              <p>{cardState === "pending" ? "If validated:" : ""}</p>
            </div>
          )}
          {!["delivered", "failure"].includes(cardState) && (
            <div className="results-block">
              <p>
                Funds will be delivered and {recepientLabel} may issue tokens to signers
              </p>
            </div>
          )}

          {cardState === "delivered" && (
            <>
              <div className="final-remarks-block">
                <p>{recepientLabel} issued tokens to signers</p>
              </div>

              <div className="result-profile-stack">
                <div className="connector">
                  <div className="arc"></div>
                </div>
                <div className="stack">
                  <ProfileAvatar cardState={cardState} imgsrc="" />
                  <ProfileAvatar cardState={cardState} imgsrc="" />
                  <ProfileAvatar cardState={cardState} imgsrc="" />
                </div>
              </div>
            </>
          )}
          <div
            className={`line ${isCompletedExpanded ? "expanded" : cardState}`}
          >
            {cardState !== "delivered" && <img src={triangleArrowIcon} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Conditions;
