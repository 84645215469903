import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { AppContext } from "../../../App";
import { FarcasterUser } from "../../../types/Users";
import {
  BORING_AVATAR_COLOR_PALLET,
  BORING_AVATAR_FALLBACK_NAME,
  FARCASTER_AUTH_TOKEN,
  SANITY_MUTATE_URL,
} from "../../../utils/constants";
import {
  ellipsisBetweenWord,
  farcasterResponseToObject,
} from "../../../utils/utils";
import SocialLoginLogo from "../Icons/SocialLoginLogo";
import SocialSignButton from "../SocialSignButton/SocialSignButton";
import Spacer from "../Spacer/Spacer";
import disconnectIcon from "./../../../assets/disconnect.svg";
import ethIcon from "./../../../assets/eth.svg";
import farcasterVerified from "./../../../assets/farcaster-verified.svg";
import "./SocialSign.scss";

import Avatar from "boring-avatars";
import { useAccount, useDisconnect } from "wagmi";

export interface SocialSignProps {
  isOpen: boolean;
  onClose: () => void;
}

const SocialSign = ({ isOpen, onClose }: SocialSignProps) => {
  const { userProfile, setUserProfile } = useContext(AppContext);
  const { address: account } = useAccount();
  const [farcasterUser, setFarcasterUser] = useState<FarcasterUser>();
  const { disconnect } = useDisconnect();
  const [farcasterUser404, setFarcasterUser404] = useState<boolean>();

  useEffect(() => {
    if (userProfile?.farcasterhandle) {
      setFarcasterUser({
        userName: userProfile.farcasterhandle,
      });
    }
  }, [userProfile]);

  const onFarcasterClick = async () => {
    if (farcasterUser404) {
      setFarcasterUser404(false);
      onDisconnect();
      return;
    }
    try {
      fetchFarcasterUser();
    } catch (error) {
      console.error(error);
    }
  };

  const onDisconnect = () => {
    disconnect();
    setUserProfile && setUserProfile(undefined);
    setFarcasterUser(undefined);
    onClose();
  };

  const updateUser = async (
    publicname?: string,
    farcasterhandle?: string,
    avatarURL?: string
  ) => {
    const payload: any = {}; // TODO: Refactor
    if (!!publicname) {
      payload["publicname"] = publicname;
    }
    if (!!farcasterhandle) {
      payload["farcasterhandle"] = farcasterhandle;
    }
    if (!!avatarURL) {
      payload["avatarURL"] = avatarURL; // TODO: ASK MARTY!!!!
    }

    const response = await axios({
      url: SANITY_MUTATE_URL,
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SANITY_AUTH_TOKEN}`,
      },
      data: {
        mutations: [
          {
            createOrReplace: {
              _id: userProfile?.id,
              _type: "user",
              wallet: account,
              ...payload,
            },
          },
        ],
      },
    });
    if (
      response.status === 200 &&
      response?.data?.results &&
      response?.data?.results[0]
    ) {
      const responseObj = response.data.results[0].document;
      setUserProfile &&
        setUserProfile({
          ...userProfile,
          id: responseObj._id,
          wallet: responseObj.wallet,
          farcasterhandle: responseObj?.farcasterhandle,
          name: responseObj?.publicname,
          profilePicture: responseObj?.avatarURL,
        });
    }
  };

  const fetchFarcasterUser = async () => {
    const farcasterUserByVerificationUrl = `https://api.warpcast.com/v2/user-by-verification?address=${account}`;

    try {
      const response = await axios({
        url: farcasterUserByVerificationUrl,
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${FARCASTER_AUTH_TOKEN}`,
        },
      });

      if (response.status === 200 && response?.data?.result?.user) {
        const farcasterObject = farcasterResponseToObject(
          response.data.result.user
        );
        updateUser(
          farcasterObject.name,
          farcasterObject.userName,
          farcasterObject.profilePicture
        );
      }
    } catch (error) {
      setFarcasterUser404(true);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="bottom"
      className="drawer-wrapper"
      size="auto"
      lockBackgroundScroll
      zIndex={104}
      overlayOpacity={0.4}
      style={{ backdropFilter: "blur(12px)", background: '#151515' }}
    >
      <div className="social-sign">
        <p className={`title ${farcasterUser404 ? "error" : ""}`}>
          {!!farcasterUser ? (
            <>
              <img src={farcasterVerified} />
              Verified
            </>
          ) : farcasterUser404
            ? "User not found" : "Claim your handle"
          }
        </p>
        {account && !!userProfile?.farcasterhandle && (
          <p className="signed-in-description">
            You're all set to make your mark
          </p>
        )}

        {account && farcasterUser404 && (
          <p className="signed-in-description">
            To claim your Farcaster handle, disconnect and switch to your
            Farcaster linked address within your wallet (Metamask, etc)
          </p>
        )}

        <SocialSignButton
          isError={farcasterUser404}
          type="Farcaster"
          onClick={onFarcasterClick}
          signedInData={
            farcasterUser
              ? {
                  publicAddress: account!,
                  userName: farcasterUser!.userName!,
                }
              : undefined
          }
        />
        {account && (
          <button disabled className="public-address">
            <img className="token-img" src={ethIcon} />
            <p>{ellipsisBetweenWord(account, 11, 14)}</p>

            <div className="status"></div>
          </button>
        )}

        {!farcasterUser && (
          <>
            <p className="or-text">or</p>
            <button
              onClick={() => {
                onClose();
                setFarcasterUser404(false);
              }}
              className="skip-btn"
            >
              Skip for now
            </button>

            {/* <p className="terms-policy-container">
              <span>
                By signing in, you agree to our <b>Terms</b>.
              </span>
              <span>
                See how we use your data in our <b>Privacy Policy</b>.
              </span>
            </p> */}
          </>
        )}
        {account && (
          <button onClick={onDisconnect} className="disconnect-btn">
            <img src={disconnectIcon} />
            <p>Disconnect</p>
          </button>
        )}
        <div className="logo-icon">
          <SocialLoginLogo
            state={
              account && farcasterUser ? "Logged In Farcaster" : "Logged Out"
            }
          />
          {account &&
            (userProfile?.profilePicture ? (
              <img
                className="profile-picture"
                src={userProfile?.profilePicture}
              />
            ) : (
              <div className="profile-picture">
                <Avatar
                  size={56}
                  name={userProfile?.id ?? BORING_AVATAR_FALLBACK_NAME}
                  variant="marble"
                  colors={BORING_AVATAR_COLOR_PALLET}
                />
              </div>
            ))}
        </div>
      </div>
      <Spacer height={60} />
    </Drawer>
  );
};

export default SocialSign;
