import "./ProfileAvatar.scss";
import claimedContractGif from "./../../../assets/claimed-contract-icon.gif";
import avatarBlurFrame from "./../../../assets/avatar-blur-frame.svg";
import profileAvatar from "./../../../assets/profileAvatarImg1.svg";

export interface ProfileAvatarProps {
  cardState: "pending" | "delivered" | "complete" | "failure";
  imgsrc: string;
}

const ProfileAvatar = ({ cardState, imgsrc }: ProfileAvatarProps) => {
  // Checks whether card is delivered (small refactoring)
  const isDelivered = cardState === "delivered";
  return (
    <div className="image-avatar">
      {/* Should be needed after last implementation */}
      {/*<img className="frame" src={avatarBlurFrame} />*/}
      {isDelivered && <img className="claim-gif" src={claimedContractGif} />}
      <div
        className="profile-container"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.12)",
        }}
      >
        <div
          className="profile-wrapper"
          style={{
            backdropFilter: isDelivered ? "blur(0)" : "blur(10px)",
            WebkitBackdropFilter: isDelivered ? "blur(0)" : "blur(10px)",
            backgroundColor: isDelivered
              ? "transparent"
              : "rgba(255, 255, 255, 0.6)",
          }}
        >
          <div className="profile-outline">
          <img
            src={imgsrc}
            className="profile"
            style={{ objectFit: "cover" }}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAvatar;
