import { useContractRead } from "wagmi";
import RefundEscrowNilli from "./../abi/RefundEscrowNilli.json";

export interface UseContractStateParams {
  contractAddress: string | undefined;
  method: "state" | "owner" | "beneficiary" | "conditionFlags" | "depositsOf";
  args?: any[];
}
const useContractState = ({
  contractAddress,
  method,
  args = [],
}: UseContractStateParams) => {
  const { data: value, error } = useContractRead({
    abi: RefundEscrowNilli,
    address: contractAddress as any,
    functionName: method,
    watch: true,
    args,
  });

  if (error) {
    console.error(error.message);
    return undefined;
  }
  return Array.isArray(value) ? value[0] : value;
};

export default useContractState;
