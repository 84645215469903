import "./Filter.scss";
import fireIcon from "./../../../assets/trending.gif";
import diamond from "./../../../assets/top.gif";
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { isMobile } from 'react-device-detect';


const Filter = () => {

  function overlayRamp() {
    if (isMobile) {
        new RampInstantSDK({
            hostAppName: 'nilli',
            hostLogoUrl: 'https://stage1-nilli.netlify.app/static/media/nilli-logo.7309bab50d8af74427d873dd09bd65da.svg',
            url: 'https://ri-widget-staging.firebaseapp.com/', // testing purposes - comment out for prod!
            hostApiKey: 'hoybcfxfvaqvs5sgbth9582b5kcyyub5oyby8vbg', // staging key
            selectedCountryCode: 'US',
            defaultAsset: 'ETH_USDC',
            variant: 'mobile'
            }).show();
    }
    else {
        new RampInstantSDK({
            hostAppName: 'nilli',
            hostLogoUrl: 'https://stage1-nilli.netlify.app/static/media/nilli-logo.7309bab50d8af74427d873dd09bd65da.svg',
            url: 'https://ri-widget-staging.firebaseapp.com/', // testing purposes - comment out for prod!
            hostApiKey: 'hoybcfxfvaqvs5sgbth9582b5kcyyub5oyby8vbg', // staging key
            selectedCountryCode: 'US',
            defaultAsset: 'ETH_USDC',
            variant: 'desktop'
            }).show();
    }
}

  return (
    <div className="filter">
      <div className="switches-container">
        <input
          type="radio"
          id="switchTrending"
          name="switchPlan"
          value="Trending"
          onChange={() => console.log("Changed!!")}
          checked
        />
        <input type="radio" id="switchTop" name="switchPlan" value="Top" />
        <label htmlFor="switchTrending text">Trending</label>
        <label htmlFor="switchTop text" style={{ marginRight: '3px' }}>Top</label>
        {/* Put Trending & Top labels to spans so its easier to manipulate them */}
        <div className="switch-wrapper">
          <div className="switch">
            <div>
              <img src={fireIcon} />
              <span style={{ marginRight: '2px' }}>Trending</span>
            </div>
            <div>
              <img src={diamond} />
              <span>Top</span>
            </div>
          </div>
        </div>
      </div>
      {/* @Satyam, commenting out for now - will need to style appropriately/include when we have an API key from Ramp */}
        {/* <button className="card" onClick={overlayRamp}>
          On-Ramp
        </button> */}
    </div>
  );
};

export default Filter;
