import "./SocialSignButton.scss";
import twitterIcon from "./../../../assets/twitter.svg";
import farcasterIcon from "./../../../assets/farcaster.svg";
import arrow from "./../../../assets/arrow-right.svg";
import verified from "./../../../assets/verified-white.svg";

export interface SocialSignButtonProps {
  isError?: boolean;
  onClick: () => void;
  type: "Twitter" | "Farcaster";
  signedInData?: {
    userName: string;
    publicAddress: string;
  };
}
const SocialSignButton = ({
  onClick,
  type,
  signedInData,
  isError,
}: SocialSignButtonProps) => {
  const getButtonText = () => {
    if (signedInData) return <p>Signed in as @{signedInData.userName}</p>;
    if (isError) return <p>Disconnect and try again</p>;
    return <p>Claim your {type} handle</p>;
  };
  return (
    <button
      disabled={!!signedInData && !!signedInData.publicAddress}
      className={`social-sign-button ${type} ${
        !!signedInData ? "signed-in" : ""
      }`}
      onClick={onClick}
    >
      {!isError && (
        <img
          className="type-img"
          src={type === "Farcaster" ? farcasterIcon : twitterIcon}
        />
      )}
      {getButtonText()}

      <img className="right-img" src={!!signedInData ? verified : arrow} />
    </button>
  );
};

export default SocialSignButton;
