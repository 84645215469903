import { ConnectButton } from "@rainbow-me/rainbowkit";
import { FC, Ref } from "react";

type Props = {
  btnRef: Ref<any>;
};

const WalletConnect: FC = () => {
  return <ConnectButton />;
};

export default WalletConnect;

export const CustomConnectButton: FC<Props> = ({ btnRef }) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,

        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    style={{ display: "none" }}
                    onClick={openConnectModal}
                    type="button"
                    ref={btnRef}
                  >
                    Connect Wallet
                  </button>
                );
              }

              return null;
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
