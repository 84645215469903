import { FarcasterUser } from "../types/Users";
import axios from "axios";

export const dollarToCompactUnits = (amount: number) => {
  if (amount / 1000000 >= 0.1) {
    return (amount / 1000000).toFixed(2) + "M";
  }

  if (amount / 1000 >= 0.1) {
    return (amount / 1000).toFixed(2) + "K";
  }

  return `${amount}`.substring(0, 5);
};

export const tokenToUSD = (tokenAmount: number, tokenPrice: number): number =>
  +(tokenAmount * tokenPrice).toFixed(2);

export const ellipsisBetweenWord = (
  word: string,
  leftLength: number,
  rightLength: number
) => {
  if (!word) return "";
  return (
    word.substring(0, leftLength) +
    "..." +
    word.substring(word.length - rightLength, word.length)
  );
};

export const farcasterResponseToObject = (response: any): FarcasterUser => {
  return {
    name: response.displayName,
    bio: response?.profile?.bio?.text ?? "",
    followerCount: response.followerCount,
    followingCount: response.followingCount,
    location: response?.profile?.location,
    profilePicture: response?.pfp?.url,
    userName: response.username,
    isVerified: response?.pfp?.verified,
  } as FarcasterUser;
};

export const getSanityContractListOffChainDataURL = (
  contractAddress?: string
) => {
  return (
    "https://lklbypd7.api.sanity.io/v2021-10-21/data/query/production?query=*[_type == 'contract' %26%26 address == '" +
    contractAddress +
    "']{ 'headerpic': headerpic.asset->url, 'profilepic': profilepic.asset->url, 'id': _id, 'creatorpic':creatorpic.asset->url }"
  );
};

export const getUserFromAddressURL = (publicAddress: string) =>
  `https://lklbypd7.api.sanity.io/v2021-10-21/data/query/production?query=*[_type == "user" %26%26 wallet == "${publicAddress}"]`;

export const replaceAt = (str: string, replacement: string, index: number) => {
  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  );
};

export const getUserSignerListFromContract = (
  contractId: string
) => `https://lklbypd7.api.sanity.io/v2021-10-21/data/query/production?query=*[_type == 'signers' %26%26 childcontract._ref == '${contractId}']{ ..., 'userAddr': *[_type == 'user' %26%26 wallet == ^.signer]{ _id, wallet, publicname, farcasterhandle, avatarURL } }
`;
