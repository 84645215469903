import { utils } from "ethers";
import { useContractReads } from "wagmi";
import RefundEscrowNilli from "./../abi/RefundEscrowNilli.json";
const escrowInterface = new utils.Interface(RefundEscrowNilli);

export interface UseContractStateParams {
  contractAddress?: string;
  walletAddresses?: string[];
  method: string;
}
const useContractStates = ({
  contractAddress,
  walletAddresses = [],
  method,
}: UseContractStateParams) => {
  const calls = contractAddress
    ? walletAddresses?.map((address) => ({
        address: contractAddress as any,
        abi: RefundEscrowNilli,
        functionName: method,
        args: [address],
      })) ?? []
    : [];
  const { data: results, error } = useContractReads({
    contracts: calls,
    watch: true,
  });
  (results ?? []).forEach((result, idx) => {
    if (!result && error) {
      console.error(
        `Error encountered calling 'totalSupply' on ${calls[idx]?.address}: ${error}`
      );
    }
  });

  const walletAddressToAmountEth: { [wallet: string]: any } = {};
  for (let i = 0; i < (results?.length || 0); i++) {
    walletAddressToAmountEth[walletAddresses[i]] = results?.[i];
  }
  return walletAddressToAmountEth;
};

export default useContractStates;
