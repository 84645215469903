import "./TabNavigation.scss";
import { ReactComponent as NewThumbIcon } from "./../../../assets/new-thumb-icon.svg";
import signHandIcon from "./../../../assets/sign-hand-icon.png";
import signHandIconClosed from "./../../../assets/sign-hand-icon-closed.png";
import { ReactComponent as HamburgerIcon } from "./../../../assets/hamburger-icon.svg";
import avatar from "./../../../assets/dummy-profile-avatar.svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import Avatar from "boring-avatars";
import Drawer from "react-modern-drawer";
import {
  BORING_AVATAR_COLOR_PALLET,
  BORING_AVATAR_FALLBACK_NAME,
} from "../../../utils/constants";

export interface TabNavigationProps {
  onListClick: () => void;
  onThumbClick: () => void;
  onContractCreateClick: (value: boolean) => void;
  isContractCraeteModalOpen: boolean;
  isMenuOpen: boolean;
}
const TabNavigation = ({
  onListClick,
  onThumbClick,
  onContractCreateClick,
  isContractCraeteModalOpen,
  isMenuOpen,
}: TabNavigationProps) => {
  const { userProfile } = useContext(AppContext);

  return (
    <>
      <div className="tab-navigation">
        <HamburgerIcon
          onClick={onListClick}
          className="list"
          style={{ fill: isMenuOpen ? "#fff" : "#949494" }}
        />
        <div onClick={onThumbClick} className="thumb">
          <NewThumbIcon className="logo" />
          {userProfile &&
            (userProfile.profilePicture ? (
              <img className="avatar" src={userProfile?.profilePicture} />
            ) : (
              <div className="avatar">
                <Avatar
                  size={24}
                  name={userProfile?.id ?? BORING_AVATAR_FALLBACK_NAME}
                  variant="marble"
                  colors={BORING_AVATAR_COLOR_PALLET}
                />
              </div>
            ))}
        </div>
        <img
          onClick={() => onContractCreateClick(!isContractCraeteModalOpen)}
          className="sign-img"
          src={isContractCraeteModalOpen ? signHandIcon : signHandIconClosed}
        />
      </div>
      <Drawer
        open={isContractCraeteModalOpen}
        onClose={() => onContractCreateClick(false)}
        direction="bottom"
        zIndex={104}
        style={{ background: "#151515", borderRadius: "20px 20px 0 0" }}
        size={319}
        lockBackgroundScroll
      >
        <div className="create-wrapper">
          <div className="create-image-block"></div>
          <img className="create-hand-image" src={signHandIcon} />
          <p className="title">Create a contract</p>
          <p className="subtitle">
            While in Beta, Nilli is collaborating with select creators to bring
            their contracts to life. 
            <a href="mailto:hello@nilli.com" className="link">
              Pitch us here
            </a>
            and we’ll get back to you soon!
          </p>
          <a
            href="https://nilli.gitbook.io/docs/how-nilli-beta-works"
            className="create-button"
            target="_blank"
          >
            Learn more →
          </a>
        </div>
      </Drawer>
    </>
  );
};

export default TabNavigation;
