import { useState } from "react";
import { SmartContract } from "../../../types/SmartContract";
import Card from "../../Common/Card/Card";
import Filter from "../../Common/Filter/Filter";
import Sign from "../Sign/Sign";
import "./ContractListing.scss";
import { ReactComponent as InfoCarrotIcon } from "./../../../assets/nilli_info_carrot.svg";
import Drawer from "react-modern-drawer";

export interface ContractListingProps {
  contracts: SmartContract[];
}

const ContractListing = ({ contracts }: ContractListingProps) => {
  const [signModalOpenFor, setsignModalOpenFor] = useState<SmartContract>();

  return (
    <>
      <div className="contract-listing">
        <Filter />
        <div className="crowd-contracts-block">
          <p>Contracts</p>
          <InfoCarrotIcon className="crowd-icon" />
        </div>
        {contracts.map((contract, index) => (
          <Card
            onSignClick={() => setsignModalOpenFor(contract)}
            contract={contract}
            key={index}
          />
        ))}
      </div>
      <Drawer
        open={!!signModalOpenFor}
        onClose={() => setsignModalOpenFor(undefined)}
        direction="bottom"
        className="drawer-wrapper"
        overlayOpacity={0.4}
        size="calc(100% - 30px)"
        lockBackgroundScroll
      >
        <Sign
          contract={signModalOpenFor}
          shouldClose={() => setsignModalOpenFor(undefined)}
        />
      </Drawer>
    </>
  );
};

export default ContractListing;
