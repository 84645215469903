import { useContext, useEffect, useState } from "react";
import { useAccount, useBalance } from "wagmi";
import { AppContext } from "../../../App";
import useContractState from "../../../hooks/useContractState";
import {
  Condition,
  OffChainContractData,
  SmartContract,
} from "../../../types/SmartContract";
import { SANITY_MUTATE_URL } from "../../../utils/constants";
import {
  dollarToCompactUnits,
  getSanityContractListOffChainDataURL,
  getUserSignerListFromContract,
  tokenToUSD,
} from "../../../utils/utils";
import Conditions from "../Conditions/Conditions";
import Signers from "../Signers/Signers";
import { ReactComponent as FingerPrintLogo } from "./../../../assets/nilli-new-logo.svg";
import roundCheckIcon from "./../../../assets/round-check.svg";
import shareIcon from "./../../../assets/share-icon.svg";
import signIcon from "./../../../assets/sign-icon.png";
import timerIcon from "./../../../assets/timer.svg";
import "./Card.scss";

import axios from "axios";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { BORING_AVATAR_COLOR_PALLET } from "../../../utils/constants";
import Loader from "../Loader/Loader";

import Avatar from "boring-avatars";
import { BORING_AVATAR_FALLBACK_NAME } from "../../../utils/constants";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";

import { formatEther } from "ethers/lib/utils.js";
import useEthereumPrice from "../../../hooks/useEthereumPrice";
import { Signer } from "../../../stubs/signers";
import "./Card.scss";
import useContractStates from "../../../hooks/useContractStates";

dayjs.extend(localizedFormat);

export interface CardProps {
  contract: SmartContract;
  onSignClick: () => void;
}

/* TODOs:
Make heading max 2 lines.
Ask for light font and update "staked for"
Make stated for card max 2 lines
Gif sizes are very big! Compression?
*/
const Card = ({ contract, onSignClick }: CardProps) => {
  const { userProfile, openWalletModal, openShareDrawer } =
    useContext(AppContext);

  const [isExpanded, setExpanded] = useState(false);
  const [signing, setSigning] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [cardState, setCardState] = useState<
    "complete" | "pending" | "delivered" | "failure"
  >("pending");
  const [currentAmountEther, setCurrentAmountEther] = useState("0");
  const [currentAmountUSD, setCurrentAmountUSD] = useState("0");
  const [offChainContractData, setOffChainContractData] =
    useState<OffChainContractData>();

  const { address } = useAccount();

  const { data: tempAddrBal } = useBalance({
    address: contract?.onChain?.contractAddress as any,
  });
  const etherPrice = useEthereumPrice();
  const price = useEthereumPrice();
  console.log({ price });

  const getOffChainContractData = async () => {
    const response = await axios({
      url: getSanityContractListOffChainDataURL(
        contract.onChain?.contractAddress
      ),
      method: "GET",
    });
    // TODO: Error Handling!!
    console.log({ data2: response.data.result });
    setOffChainContractData(response.data.result[0]);
  };

  const getSignersData = async () => {
    const signersListInfoResponse = await axios({
      url: getUserSignerListFromContract(offChainContractData!.id),
      method: "GET",
    });
    // TODO: Error Handling!!
    if (!!signersListInfoResponse.data.result) {
      const signersOfContract = signersListInfoResponse.data.result.map(
        (signerObj: any) => {
          const userAddr = signerObj.userAddr[0];

          return {
            id: userAddr ? userAddr._id : undefined,
            publicAddress: signerObj.signer,
            imageUrl: userAddr ? userAddr.avatarURL : undefined,
            isFarcasterVerified: userAddr ? !!userAddr.farcasterhandle : false,
            name: userAddr ? userAddr.publicname : undefined,
            userName: userAddr ? userAddr.farcasterhandle : undefined,
            dateSigned: signerObj._createdAt,
          } as Signer;
        }
      );
      setSigners(signersOfContract);
    }
  };
  useEffect(() => {
    getOffChainContractData();
  }, []);

  useEffect(() => {
    if (offChainContractData?.id) {
      getSignersData();
    }
  }, [offChainContractData?.id]);

  useEffect(() => {
    if (!!signers && !!address) {
      setIsSigned(signers.map((s) => s.publicAddress).includes(address));
    } else {
      setIsSigned(false);
    }
  }, [signers, address, userProfile]);

  useEffect(() => {
    calculateETHBal();
  }, [tempAddrBal, etherPrice]);

  const calculateETHBal = () => {
    if (!!tempAddrBal && !!etherPrice) {
      const etherBalance = formatEther(tempAddrBal.value.toString());
      const usdValue = tokenToUSD(+etherBalance, +etherPrice);

      setCurrentAmountEther(etherBalance);
      setCurrentAmountUSD(usdValue.toString());
    }
  };

  const getDateFromTimestamp = (timestamp: number) => {
    if (`${timestamp}`.length === 10) {
      return dayjs(+`${timestamp}000`).format("MM/DD/YYYY");
    }
    return dayjs(timestamp).format("MM/DD/YYYY");
  };

  const signContract = async () => {
    if (!isSigned) {
      setSigning(true);
      const response = await axios({
        url: SANITY_MUTATE_URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_AUTH_TOKEN}`,
        },
        data: {
          mutations: [
            {
              createOrReplace: {
                _type: "signers",
                signer: address,
                childcontract: {
                  _ref: offChainContractData!.id,
                  _type: "reference",
                },
              },
            },
          ],
        },
      });
      // TODO: ERROR Handling!!
      setSigning(false);
      getSignersData(); // TODO state management!!
    }
    onSignClick();
  };

  const onSignContract = (e: any) => {
    e.stopPropagation();
    if (!!address) {
      signContract();
    } else {
      openWalletModal && openWalletModal();
    }
  };

  const getCardStateText = (
    state: "complete" | "pending" | "delivered" | "failure"
  ) => {
    const stateToTextMap = {
      complete: "unlocked",
      delivered: "delivered",
      pending: "locked",
      failure: "refunded",
    };

    return stateToTextMap[state];
  };

  const contractState = useContractState({
    contractAddress: contract.onChain?.contractAddress,
    method: "state",
  });

  const contractConditions = useContractState({
    contractAddress: contract.onChain?.contractAddress,
    method: "conditionFlags",
  });

  useEffect(() => {
    console.log({ contractState });
    console.log({ contractConditions });
    if (contractState !== undefined) {
      switch (contractState) {
        case 0:
          setCardState("pending");
          break;
        case 1:
          setCardState("complete");
          break;
        case 2:
          setCardState("failure");
          break;
        case 3:
          setCardState("delivered");
          break;
      }
    }
  }, [contractState]);

  useEffect(() => {
    // todo: Major mutation error possibility -  Check why it is not creating a new reference
    const conditions: Condition[] = [...(contract.onChain?.conditions ?? [])];

    if (
      !!contractConditions &&
      contractConditions.length === conditions.length
    ) {
      conditions.map(
        (condition, index) =>
          (condition.isValidated = contractConditions[index])
      );
    }
    // TODO!!
    // setConditions(conditions);
  }, [contractConditions, contract.onChain?.conditions]);

  const shareContractData = () => {
    const isMobile = /Android|iPhone/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      navigator.share({ url: 'https://demo.nilli.com' })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      openShareDrawer && openShareDrawer();
    }
  }

  return (
    <>
      <div className="card" onClick={() => setExpanded(!isExpanded)}>
        <img className="image-overlay" src={offChainContractData?.headerpic} />
        <ProfileAvatar
          cardState={cardState}
          imgsrc={offChainContractData?.profilepic ?? ""}
        />
        {/* Transformed finger iamge to svg to be able to easilt change colors depends on state */}
        {/* Should not be shwon for failure contracts */}
        {cardState !== "failure" && (
          <FingerPrintLogo
            className="logo"
            style={{
              fill: isSigned ? "#DBBC0E" : "#949494",
              opacity: !isSigned ? 0.5 : 1,
            }}
          />
        )}
        {isSigned &&
          (userProfile?.profilePicture ? (
            <img className="user-avatar" src={userProfile?.profilePicture} />
          ) : (
            <div className="user-avatar">
              <Avatar
                size={63}
                name={userProfile?.id ?? BORING_AVATAR_FALLBACK_NAME}
                variant="marble"
                colors={BORING_AVATAR_COLOR_PALLET}
              />
            </div>
          ))}
        {/* Add abillity to change opaciy depends on expended property */}
        <img
          onClick={shareContractData}
          className="share-icon"
          src={shareIcon}
          style={{ opacity: isExpanded ? 1 : 1.0 }}
          alt="share-icon"
        />
        <div className="content">
          <p className="heading">{contract.onChain?.title}</p>
          <div className="progress-target">
            <progress
              className={cardState}
              value={
                ["delivered", "failure"].includes(cardState)
                  ? "100"
                  : (+currentAmountUSD).toString()
              }
              max="100"
            />
            <p className="progress-target-text">
              ${dollarToCompactUnits(contract.offChain?.targetAmount ?? 0)}
            </p>
          </div>
          <div className="description">
            <p className="staked-text">
              <span className="current-amount">${currentAmountUSD}</span>
              <span className="state">{getCardStateText(cardState)}</span>
              {cardState !== "failure" && (
                <span className="helper">
                  {cardState === "complete"
                    ? "by"
                    : cardState === "delivered"
                    ? "to"
                    : "for"}
                </span>
              )}
            </p>
            {cardState !== "failure" && (
              <div className={`staked-for-card ${cardState}`}>
                {/* <img className="profile" src="https://picsum.photos/95" /> */}
                <p className={true ? "verified-text" : ""}>
                  {contract.offChain?.stakedFor?.label ?? ""}
                </p>
                {/* <img className="verified-img" src={roundCheckIcon} /> */}
              </div>
            )}
          </div>
          <Conditions
            conditions={contract?.onChain?.conditions ?? []}
            isExpanded={isExpanded}
            cardState={cardState}
            recepientLabel={contract.offChain?.stakedFor?.label ?? ""}
          />
          {isExpanded && !["delivered", "failure"].includes(cardState) && (
            <div className="note-message">
              <img src={timerIcon} />
              <p>
                {`If not delivered by
                ${getDateFromTimestamp(contract.onChain?.expirationDate ?? 0)},
                signers can reclaim their assets.`}
              </p>
            </div>
          )}
          <div className="footer">
            <div className="date-user">
              {/* Now date should be shown always according to design */}
              <p className="date">
                {/* todo */}
                {cardState === "failure" ? "Refund issued " : "Created"} 1d ago
              </p>
              <div className="user">
                <img
                  className="avatar"
                  src={offChainContractData?.creatorpic}
                />
                <div className="user-name">
                  <p className="footer-text">
                    {contract.offChain?.creator?.label ?? ""}
                  </p>
                  <img src={roundCheckIcon} />
                </div>
              </div>
            </div>
            <Signers signers={signers} contractAddress={contract?.onChain?.contractAddress} />
          </div>
          {isExpanded && cardState !== "failure" && (
            <button
              disabled={cardState === "delivered"}
              onClick={(e) => onSignContract(e)}
              className={`sign-btn ${signing ? "sigining" : ""} ${
                isSigned ? "signed" : ""
              }`}
            >
              {!signing && (
                <>
                  {!isSigned && (
                    <img src={signIcon} className="signin-button-image" />
                  )}
                  <span className={`signin-button-text ${!isSigned ? 'margin-left' : ''}`}>
                    {isSigned ? "🥳 Signed" : "Sign"}
                  </span>
                </>
              )}
              {signing && <Loader />}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Card;
