import "./Signers.scss";
import signIcon from "./../../../assets/sign-icon.png";
import { Signer } from "../../../stubs/signers";
import Avatar from "boring-avatars";
import {
  BORING_AVATAR_COLOR_PALLET,
  BORING_AVATAR_FALLBACK_NAME,
} from "../../../utils/constants";
import useContractStates from "../../../hooks/useContractStates";
import { useEffect, useState } from "react";

export interface SignersProps {
  signers: Signer[]; // todo: number will replace signer object later
  contractAddress?: string;
}

const Signers = ({ signers, contractAddress }: SignersProps) => {
  const ethStakedBySigners = useContractStates({
    contractAddress,
    method: "depositsOf",
    walletAddresses: signers
      .map((s) => s.publicAddress)
      .filter((address) => !!address) as string[],
  });

  const getSignersAvatars = () => {
    if (signers.length === 0) return [];
    if (signers.length < 6) {
      return signers.sort((x, y) =>
          ethStakedBySigners[y.publicAddress!] -
          ethStakedBySigners[x.publicAddress!]
        ).slice(0, signers.length).reverse();
    }

    return signers.sort((x, y) =>
        ethStakedBySigners[y.publicAddress!] -
        ethStakedBySigners[x.publicAddress!]
      ).slice(0, 6).reverse();
  }

  return (
    <div className="signers">
      <div className="avatar-stack">
        {getSignersAvatars().map((signer, i) => {
          return (
            <span className="avatar" key={i}>
              {signer?.imageUrl ? (
                <img src={signer?.imageUrl} className="avatar-image"/>
              ) : (
                <Avatar
                  size={27}
                  name={signers[i]?.id ?? BORING_AVATAR_FALLBACK_NAME}
                  variant="marble"
                  colors={BORING_AVATAR_COLOR_PALLET}
                />
              )}
            </span>
        )})}
      </div>
      <div className="count">
        <img src={signIcon} />
        <p className="count-text">{signers.length}</p>
      </div>
    </div>
  );
};

export default Signers;
